import ProductList10 from "./ProductList10";
import Contact from "./Contact";
import Landing from "./Landing";
import Services from "./Services";
import UncontrolledExampleTop from "./SideBarTopHome";

function Home() {

  return (
    <>
      <Landing />
      <UncontrolledExampleTop/>
      <Services />
      < ProductList10 />
      <Contact />
    </>
  );
}

export default Home;
